import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setShowEmojiModal, setShowSavedReplies} from "../../../actions/ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceSmile, faPaste} from "@fortawesome/free-regular-svg-icons";
import TagInput from "../TagInput";

const TriggersBlock = ({rule, isValidForm}) => {
    const dispatch = useDispatch()
    const [trigger, setTrigger] = useState((rule && rule.attributes.trigger) || "keywords")

    const handleTrigger = (e) => {
        setTrigger(e.currentTarget.value)
        isValidForm(e)
    }

    return (
        <>
            <div className="form-group mb-2">
                <label>Trigger</label>
                <select className="form-select" name="rule[trigger]" onChange={handleTrigger}
                        defaultValue={trigger} required="required">
                    <option value="keywords">Comment contains certain keywords</option>
                    <option value="only_emoji">Comment contains just an emoji</option>
                    <option value="only_mention">Comment contains just a mention</option>
                </select>
            </div>

            {!trigger.includes("only_") &&
                <div className="form-group mb-2">
                    <label>Keywords</label>
                    <TagInput name="rule[keywords]" copy={true} tags={(rule && rule.attributes.keywords) || []}
                              onChange={isValidForm}/>
                </div>
            }
        </>
    )
}

export default React.memo(TriggersBlock)