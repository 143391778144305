import {DIRECT_MESSAGES_ACTIONS, PAGES_ACTIONS} from "../constants";

const initState = {
    conversations: [],
    messagesList: [],
    lastActive: null,
    unarchivedConvosCount: 0,
    filters: {
        top: {
            read: ""
        },
        bottom: {
            resolved: "",
            archived: ""
        }
    }
}


export function directMessagesReducer(state=initState, action) {
    const {payload} = action

    switch (action.type) {
        case DIRECT_MESSAGES_ACTIONS.REQUEST_CONVOS:
            return {
                ...state,
                conversations: payload.data
            }
        case DIRECT_MESSAGES_ACTIONS.REQUEST_MESSAGES:
            return {
                ...state,
                messagesList: payload.data
            }
        case DIRECT_MESSAGES_ACTIONS.ADD_DIRECT_MESSAGES:
            return {
                ...state,
                messagesList: [...state.messagesList, payload.data],
            }
        case DIRECT_MESSAGES_ACTIONS.UPDATE_DIRECT_MESSAGE:
            return {
                ...state,
                messagesList: state.messagesList.map(m => {
                    if (m.id === payload.messageId) {
                        return {
                            ...m,
                            attributes: Object.assign(m.attributes, payload.attributes)
                        }
                    } else {
                        return {
                            ...m
                        }
                    }
                })
            }
        case DIRECT_MESSAGES_ACTIONS.ADD_LAST_ACTIVE:
            return {
                ...state,
                lastActive: payload.data
            }
        case DIRECT_MESSAGES_ACTIONS.ADD_CONVO:
            return {
                ...state,
                conversations: [payload.data, ...state.conversations]
            }
        case DIRECT_MESSAGES_ACTIONS.UPDATE_CONVO:
            return {
                ...state,
                conversations: state.conversations.map(s => {
                    if (s.id === payload.convoId) {
                        return {
                            ...s,
                            attributes: Object.assign(s.attributes, payload.attributes)
                        }
                    } else {
                        return {
                            ...s
                        }
                    }
                })
            }
        case DIRECT_MESSAGES_ACTIONS.CHANGE_DM_FILTER:
            console.log('state.filters.read', state.filters.read)
            return {
                ...state,
                filters: payload.top ? {
                    bottom: {...state.filters.bottom},
                    top: {[payload.name]: payload.value}} : {
                    top: {...state.filters.top},
                    bottom: payload.name ? {...initState.filters.bottom, [payload.name]: payload.value} : {...initState.filters.bottom}
                }
            }
        case DIRECT_MESSAGES_ACTIONS.SET_UNARCHIVED_CONVOS_COUNT:
            return{
                ...state,
                unarchivedConvosCount: payload.num
            }
        default:
            return state
    }
}



// payload.name ? {
//     ...initState.filters,
//     [payload.name]: payload.value
// } : {...initState.filters}