import React, {useEffect, useRef, useState} from 'react'
import PlanItem from "../../../UI/PlanItem";
import {CSRF} from "../../../../constants";
import axios from "axios";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const AccountSettingsAccount = ({account_level, profile, admin}) => {

    const accountData = useRef()
    const {name, fb_pages_limit, sub_users_limit, trial} = account_level.attributes
    const {comments_count, comments_limit, pages_count, ai_replies_limit, ai_replies_count,  next_billing_date, sub_users_count, expire_date, show_plans, hide_subs_btn, role} = profile.attributes
    const [accountLevels, setAccountLevels] = useState([])

    useEffect(()=>{
        try {
            console.log("LOAD levels")
            axios.get(`/account_level/`)
                .then(json => {
                    // console.log('json.data.data', json.data.data)
                    setAccountLevels(json.data.data)
                }).catch(error => {
                console.log(error)
            })
        } catch(e) {
            console.error("Error of requesting account levels data")
        }
    },[])

    return (
        <div>
            <div className="my-4">
                Here you can view your current plan and usage.
            </div>

            <div className="pb-3 mt-2" ref={accountData}>
                <div className="my-2">

                    Your current plan:
                    {(!show_plans || trial) && <strong> {name}</strong>}
                </div>
                {/*<div className="my-2">*/}
                {/*    Next billing date:*/}
                {/*    {!show_plans && <strong> {next_billing_date}</strong>}*/}
                {/*</div>*/}
                <div className="my-2">
                    Comments:
                    <strong> {comments_count} / {comments_limit == null ? "unlimited" : comments_limit} comments</strong>
                    <FontAwesomeIcon icon={faCircleInfo} className="fa-fw mx-2 circle-info" data-tip="We’ll notify you when you exceed 90% of the allowed usage of your current plan, so you will still have the opportunity to upgrade to a higher plan in time. When you reach your allowed usage, CommentGuard will stop processing comments until your next billing period starts."/>
                </div>
                {ai_replies_limit > 0 &&
                    <div className="my-2">
                        AI-replies:
                        <strong> {ai_replies_count} / {ai_replies_limit} replies</strong>
                    </div>
                }
            </div>

            {role == "owner" && !hide_subs_btn && !trial &&

                <form method="POST" action="/create_customer_portal_session">
                    <input type="hidden" name="authenticity_token" value={CSRF}/>
                    <button className="btn btn-primary select-plan mb-4" type="submit">Manage subscription</button>
                </form>
            }

            <div className="row">
                {accountLevels.map((level)=>{
                    return <PlanItem key={level.id} level={level} selected={name == level.attributes.name} show_plans={show_plans} cur_trial={trial}/>
                })}
            </div>

            <ReactTooltip effect="solid" html={true} className="switch-tooltip"/>
        </div>
    )
}

export default React.memo(AccountSettingsAccount)