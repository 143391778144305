import React, {lazy, useState, Suspense, useEffect} from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const Moderation = lazy(() => import("../Moderation"));
const PageMenu = lazy(() => import("../PageMenu"));
const Comments = lazy(() => import("../Comments"));

import {useSelector} from "react-redux";
import {useAbility} from "@casl/react";
import {AbilityContext} from "../../../utils/app";

import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import './Dashboard.scss'
import Rules from "../Rules";
import Notifications from "../Notifications";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import Analytics from "../Analytics";
import Messages from "../Messages";

const Dashboard = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const page = useSelector(state => state.pages.facebook.activePage.details.id)
    const ability = useAbility(AbilityContext)
    const {hash: locationHash} = useLocation()

    useEffect(()=>{
        let tabs = ['comments']
        if(page){
            ability.can("access", "DirectMessage") && tabs.push("messages")
            ability.can("update", "PagesController") && tabs.push("settings")
            ability.can("access", "Rule") && tabs.push("rules")
            ability.can("access", "AnalyticsController") && tabs.push("analytics")
        }
        const [pageId, path] = locationHash.split("/")
        const tab_id = tabs.indexOf(path)
        if(tab_id !==-1){
            setTabIndex(tab_id)
        }else{
            setTabIndex(0)
        }
    },[locationHash])

    return (
        <div className="col-12 mb-4">
            <Suspense fallback={<div>Loading...</div>}>
                <PageMenu/>
            </Suspense>
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    <Tab>Comments</Tab>
                    {page &&
                        <>
                            {ability.can("access", "DirectMessage") && <Tab>Messages</Tab>}
                            {ability.can("update", "PagesController") && <Tab>Moderation</Tab>}
                            {ability.can("access", "Rule") && <Tab>Automation</Tab>}
                            {ability.can("access", "AnalyticsController") && <Tab>Analytics</Tab>}
                        </>
                    }
                </TabList>
                <TabPanel>
                    <div className="my-4">
                        Here you’ll find an overview of the comments you’ve received on your posts and ads.
                    </div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Comments inbox_zero={props.inbox_zero}/>
                    </Suspense>
                </TabPanel>
                {page && <>
                    {ability.can("access","DirectMessage") && <TabPanel>
                        <Messages/>
                    </TabPanel>}
                    {ability.can("update","PagesController") && <TabPanel>
                        <div className="my-4">
                            Here you can define the moderation settings that will be used to process new comments you'll receive on your posts and ads.
                        </div>
                        {/*<div className="line"/>*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            {page && <Moderation admin={props.admin}/>}
                        </Suspense>
                    </TabPanel>}
                    {ability.can("access","Rule") && <TabPanel>
                        <Rules/>
                    </TabPanel>}
                    {ability.can("access","AnalyticsController") && <TabPanel>
                        <Analytics/>
                    </TabPanel>}
                </>}
            </Tabs>
        </div>

    )
}

export default React.memo(Dashboard)