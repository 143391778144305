import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setShowEmojiModal, setShowSavedReplies} from "../../../actions/ui";
import EmojiContainer from "../EmojiContainer";
import SavedRepliesModal from "../SavedRepliesModal";

const AdditionalModals = ({setMessage, isValidForm, showSavedReplies, setShowSavedReplies}) => {
    const dispatch = useDispatch()

    const clickOutsideEmoji = (e) => {
        if (!e.target.classList.contains("emoji-icon") && e.target.localName !== "path" && e.target.localName !== "em-emoji-picker") {
            dispatch(setShowEmojiModal(false))
        }
    }

    return (
      <>
        <EmojiContainer isValidForm={isValidForm} clickOutside={clickOutsideEmoji} className="col-lg-9 mx-auto emoji-modal-fixed"/>
        {showSavedReplies && <>
            <SavedRepliesModal fade={false} setShowSavedReplies={setShowSavedReplies} setMessage={setMessage}/>
            <div className = "modal-backdrop fade show noclose"/>
        </>}
    </>
    )
}

export default React.memo(AdditionalModals)