import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setShowEmojiModal, setShowSavedReplies} from "../../../actions/ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceSmile, faPaste} from "@fortawesome/free-regular-svg-icons";

const ActionBlock = ({rule, msgRef, isValidForm, setShowSavedReplies, privateRef}) => {
    const dispatch = useDispatch()
    const [action, setAction] = useState((rule && rule.attributes.action) || "reply")
    const type = useSelector(state => state.pages.facebook.activePage.details.type)

    const changeAction = (e) => {
        setAction(e.target.value)
    }

    useEffect(()=>{
        isValidForm()
    },[action])

    return (
        <>
            <div className="form-group mb-2">
                <label>Action</label>
                <select className="form-select" name="rule[action]" defaultValue={action}
                        onChange={changeAction} required="required">
                    {/*{type!=="instagram_page" && <option value="like">Like</option>}*/}
                    <option value="reply">Reply</option>
                    <option value="review">Archive</option>
                </select>
            </div>

            {(action == "reply" || type=="instagram_page") && <div className="form-group mt-2">
                <div className="reply-block reply-rules">
                            <textarea className="form-control" required="required" rows="6" required="required"
                                      name="rule[reply_text]"  id="rule_reply_text"
                                      defaultValue={rule && rule.attributes.reply_text}
                                      ref={msgRef}
                                      onChange={isValidForm}></textarea>

                    <div className="d-flex justify-content-between mx-0">
                        <div className="d-flex p-0">
                            <div className="private-checkbox">
                                <input className="form-check-input"
                                       type="checkbox"
                                       name="rule[private_reply]"
                                       value="true"
                                       ref={privateRef}
                                       defaultChecked={rule && rule.attributes.private_reply}/>
                                <label className="form-check-label ms-2 rules-private-reply">
                                    Send as private message
                                </label>
                            </div>
                        </div>

                        <div className="d-flex p-0 icon-buttons">
                            <FontAwesomeIcon icon={faPaste}
                                             className="saved-replies-icon mx-2"
                                             size="lg"
                                             onClick={()=>{setShowSavedReplies(true)}}/>
                            <FontAwesomeIcon icon={faFaceSmile}
                                             className="emoji-icon mx-2"
                                             size="lg"
                                             onClick={()=>{dispatch(setShowEmojiModal(true,"rule_reply_text"))}}/>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default React.memo(ActionBlock)