import consumer from "./consumer"
import {BRANDS_ACTIONS, COMMENTS_ACTIONS, DIRECT_MESSAGES_ACTIONS, PAGES_ACTIONS, USER_ACTIONS} from "../constants";
import {
    addNewComment, banUser, changeLinks,
    decUnreviewedCommentsCounter,
    deleteComment,
    deleteComments, incUnreviewedCommentsCounter, requestPageDetailsSuccess,
    updateComment
} from "../actions/pages";
import {errorNotify} from "../utils/app";
import {batch} from "react-redux";
import {editBrand} from "../actions/brands";
import {addLastActive, addMessage, addConvo, editConvo} from "../actions/direct_messages";

export function initNotificationChannel() {
    return (dispatch, getState) => {
        consumer.subscriptions.create("NotificationsChannel", {
            connected: function () {
                console.log("Connected to the room!");
            },
            disconnected: function () {
                console.log("DisConnected from the room!");
            },
            received: function (data) {
                const {event, payload} = data

                switch (event) {
                    case USER_ACTIONS.API_ERROR:
                        errorNotify(payload.error_message)

                        break;
                    case PAGES_ACTIONS.UPDATE_PAGE_DETAILS:
                        if(payload.id == getState().pages.facebook.activePage.details.attributes.uuid){
                            const details = {
                                ...payload,
                                details: {
                                    ...payload,
                                }
                            }
                            dispatch(requestPageDetailsSuccess(details))
                        }

                        break;
                    case PAGES_ACTIONS.ADD_COMMENT_WEBHOOK:
                        if(getState().filters.list.by_page_id == "" || payload.page_id == getState().pages.facebook.activePage.details.attributes.uuid){
                            const {list: links} = getState().links
                            const {current_page, next_page} = links
                            if (current_page == 1){
                                dispatch(addNewComment(payload))
                            }
                            dispatch(incUnreviewedCommentsCounter())
                        }

                        break;
                    case COMMENTS_ACTIONS.UPDATE_COMMENT:
                        if(getState().filters.list.by_page_id == "" || payload.page_id == getState().pages.facebook.activePage.details.attributes.uuid){
                            if(payload.remove){
                                dispatch(deleteComment(payload.data.id))
                                dispatch(decUnreviewedCommentsCounter())
                            }else{
                                dispatch(updateComment(payload.data.id, payload.data.attributes, payload.old_comment_id, true))
                            }
                        }
                        break;
                    case PAGES_ACTIONS.DELETE_COMMENT_WEBHOOK:
                        if(getState().filters.list.by_page_id == "" || payload.page_id == getState().pages.facebook.activePage.details.attributes.uuid){
                            dispatch(deleteComment(payload.comment_id))
                        }

                        break;
                    case COMMENTS_ACTIONS.DELETE_COMMENTS:
                        if(getState().filters.list.by_page_id == "" || payload.page_id == getState().pages.facebook.activePage.details.attributes.uuid){
                            dispatch(deleteComments(payload.comment_ids))
                            dispatch(decUnreviewedCommentsCounter(payload.comment_ids.length))
                        }

                        break;
                    case COMMENTS_ACTIONS.BAN_USER:
                        batch(() => {
                            payload.author_ids.map(author => {
                                dispatch(banUser(author, true))
                            })
                        })
                        break;
                    case BRANDS_ACTIONS.UPDATE_STATUS:
                        dispatch(editBrand(payload.data.id, payload.data.attributes))
                        break;
                    case DIRECT_MESSAGES_ACTIONS.ADD_MESSAGE_WEBHOOK:
                        if(payload.page_id == getState().pages.facebook.activePage.details.attributes.uuid){
                            const currentConv = getState().direct_messages.messagesList.some(m => m.attributes.sender_id === payload.attributes.sender_id)
                            console.log('currentConv', currentConv)
                            const msgExists = getState().direct_messages.messagesList.some(m => m.id === payload.id)
                            console.log('msgExists', msgExists)
                            if(currentConv && !msgExists){
                                dispatch(addMessage(payload))
                                dispatch(addLastActive(payload))
                            }

                            const convoExists = getState().direct_messages.conversations.some(c => c.id === payload.attributes.conv_id)
                            console.log('convoExists',convoExists)
                            if(convoExists){
                                !payload.attributes.reply && dispatch(editConvo(payload.attributes.conv_id, payload.attributes))
                            }else{
                                console.log('payload', payload)
                                const conv = {id: payload.attributes.conv_id, type: "dm_conversation", attributes: {
                                    recipient_id: payload.attributes.recipient_id,
                                    last_message: payload
                                    }}
                                dispatch(addConvo(conv))
                            }

                        }
                        break;
                }
            }
        })
    }
}