import React, {useCallback, useRef, useState} from 'react'
import ModalWindow from "../ModalWindow";
import {createRules, updateRules} from "../../../actions/requests";
import {useDispatch, useSelector} from "react-redux";
import TagInput from "../TagInput";
import '../ReplyForm/ReplyForm.scss'
import AdditionalModals from "./AdditionalModals";
import ActionBlock from "./ActionBlock";
import TriggersBlock from "./TriggersBlock";

const RulesModal = ({rule, handleClose}) => {
    const dispatch = useDispatch()
    const ruleForm = useRef()
    const btnRef = useRef()
    const msgRef = useRef()
    const privateRef = useRef()
    const rulesRow = useRef()
    const [showSavedReplies, setShowSavedReplies] = useState(false)

    const handleRuleForm = (e) => {
        e.preventDefault()
        const formData = new FormData(ruleForm.current)
        privateRef.current && formData.append("rule[private_reply]", privateRef.current.checked)
        if(rule){
            dispatch(updateRules(rule.id, formData))
        }else{
            dispatch(createRules(formData))
        }
        handleClose()
    }

    const setMessage = (message) => {
        msgRef.current.value = message
        isValidForm()
    }

    const isValidForm = useCallback(e => {
        let valid = false
        const formData = new FormData(ruleForm.current)

        for(let pair of formData.entries()) {
            if(!pair[1]) {
                valid = true
            }
        }

        btnRef.current.disabled = valid
    }, [])

    return (
        <ModalWindow hideClose={true}
                     header={`${rule ? 'Edit' : 'Add new'} automation`} className="giveFeedbackModal"
                     closeHandler={() => handleClose()}
        >
            <form onSubmit={handleRuleForm} ref={ruleForm}>

                <div className="row" ref={rulesRow}>
                    <div className="form-group mb-2">
                        <label>Name</label>
                        <input type="text" name="rule[name]"
                               defaultValue={rule && rule.attributes.name}
                               className="form-control"
                               required="required"
                               onChange={isValidForm}/>
                    </div>

                    <TriggersBlock rule={rule} isValidForm={isValidForm} />

                    <ActionBlock rule={rule} msgRef={msgRef} privateRef={privateRef} isValidForm={isValidForm} setShowSavedReplies={setShowSavedReplies}/>
                </div>

                <div className="row mt-4">
                    <div>
                        <button type="submit" className="btn btn-primary float-end" ref={btnRef } disabled={true}>Save</button>
                        <button type="reset" className="btn btn-secondary float-end mx-2"
                                onClick={() => handleClose()}>Cancel</button>
                    </div>
                </div>
            </form>

            <AdditionalModals setMessage={setMessage}
                              isValidForm={isValidForm}
                              showSavedReplies={showSavedReplies}
                              setShowSavedReplies={setShowSavedReplies}/>

        </ModalWindow>
    )
}

export default React.memo(RulesModal)